<template>
  <v-dialog v-model="dialog" scrollable max-width="400" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Time Block
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="timeBlockFormWrapper">
        <v-text-field
          label="Date *"
          v-model="fields.date"
          type="date"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('date')"
          :error-messages="errors['date']"
        ></v-text-field>
        <v-text-field
          label="Start Time *"
          v-model="fields.start_time"
          type="time"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('start_time')"
          :error-messages="errors['start_time']"
        ></v-text-field>
        <v-text-field
          label="End Time *"
          v-model="fields.end_time"
          type="time"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('end_time')"
          :error-messages="errors['end_time']"
        ></v-text-field>
        <v-text-field
          label="Allocation *"
          v-model="fields.allocation"
          type="number"
          outlined
          background-color="white"
          :error="errors.hasOwnProperty('allocation')"
          :error-messages="errors['allocation']"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn color="accent" text @click="save" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    saveTimeBlock: Function,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      timeBlock: {},
      key: null,
      fields: {
        date: null,
        start_time: null,
        end_time: null,
        allocation: 0,
      },
      errors: {},
    };
  },

  methods: {
    open(timeBlock = null, key = null) {
      if (timeBlock !== null) {
        this.timeBlock = timeBlock;
        this.key = key;
        this.isEditing = true;
        this.fields.date = timeBlock.date;
        this.fields.start_time = timeBlock.start_time;
        this.fields.end_time = timeBlock.end_time;
        this.fields.allocation = timeBlock.allocation;
      }

      this.dialog = true;
    },

    save() {
      this.saveTimeBlock(this.fields, this.isEditing, this.key);
      this.reset();
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.key = null;
      this.timeBlock = {};
      this.fields = {
        date: null,
        start_time: null,
        end_time: null,
        allocation: 0,
      };
      this.errors = {};
      this.$refs.timeBlockFormWrapper.scrollTop = 0;
    },
  },
};
</script>
