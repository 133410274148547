<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>All Events</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Events"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="grey white--text"
                  class="mr-2"
                  v-on="on"
                >
                  <v-icon>mdi-history</v-icon>
                </v-btn>
              </template>
              <span>Past Events</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.eventDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Event</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="events"
        no-data-text="There are no current events"
      >
        <template v-slot:item.draft="{ item }">
          <v-switch v-model="item.draft" @click="openSwitch(item)"></v-switch>
        </template>

        <template v-slot:item.date="{ item }">
          <span v-if="item.start_date === item.end_date">{{
            item.formatted_dates.start_date
          }}</span>
          <span v-else
            >{{ item.formatted_dates.start_date }} -
            {{ item.formatted_dates.end_date }}</span
          >
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip
            label
            small
            color="red white--text"
            v-if="item.event && item.event.type === 'christmas'"
            >Christmas</v-chip
          >
          <v-chip
            label
            small
            color="orange white--text"
            v-else-if="item.is_ticketed"
            >Ticketed</v-chip
          >
          <v-chip label small color="green white--text" v-else>Open</v-chip>
        </template>
        <template v-slot:item.tickets_remaining="{ item }">
          <span v-if="item.event && item.event.type === 'christmas'"
            >{{ totalRemainingTickets(item) }}/{{
              totalChristmasAllocation(item)
            }}</span
          >
          <span v-else-if="item.is_ticketed && item.event.has_allocation">
            {{ totalRemainingTickets(item) }}/{{ item.event.allocation }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-craigtoun-events-individual',
                  params: { eventId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Event</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <event-dialog ref="eventDialog" />
    <v-dialog v-model="deleteEvent.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Event</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteEvent.event.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteEvent.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventDialog from "./components/EventDialog.vue";

export default {
  components: {
    EventDialog,
  },

  data() {
    return {
      searchTerm: "",
      fields: {
        draft: true,
      },
      breadcrumbs: [
        {
          text: "Events",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
      deleteEvent: {
        dialog: false,
        event: {},
        loading: false,
      },
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Slug", value: "slug" },
        { text: "Date", value: "date" },
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Type", value: "type" },
        { text: "Draft", value: "draft" },
        {
          text: "Tickets Remaining",
          value: "tickets_remaining",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    events() {
      let events = this.$store.getters["craigtoun/eventsStore/all"];

      if (this.searchTerm !== "") {
        events = events.filter((c) => {
          const name = c.name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return events;
    },
  },

  methods: {
    openDelete(event) {
      this.deleteEvent.event = event;
      this.deleteEvent.dialog = true;
    },

    resetDelete() {
      this.deleteEvent.dialog = false;
      this.deleteEvent.event = {};
      this.deleteEvent.loading = false;
    },

    saveDelete() {
      this.deleteEvent.loading = true;

      this.$store
        .dispatch("craigtoun/eventsStore/deleteEvent", {
          appId: this.$route.params.id,
          eventId: this.deleteEvent.event.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteEvent.loading = false;
        });
    },

    openSwitch(event) {
      this.loading = true;
      this.errors = {};

      this.fields.draft = event.draft ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        eventId: event.id,
      };

      this.$store
        .dispatch("craigtoun/eventsStore/saveEventDraft", payload)
        .then(() => {})
        .catch();
    },

    totalRemainingTickets(event) {
      if (event.event === {}) {
        return;
      }

      if (event.event.type === "christmas") {
        let total = 0;

        event.tickets.forEach((t) => {
          total += parseInt(t.total_tickets);
        });

        return this.totalChristmasAllocation(event) - total;
      } else if (event.is_ticketed && event.event.has_allocation) {
        let total = 0;

        event.tickets.forEach((t) => {
          total += parseInt(t.total_tickets_over_3);
        });

        return parseInt(event.event.allocation) - total;
      }

      return;
    },
    totalChristmasAllocation(event) {
      let allocation = 0;

      event.event.time_blocks.forEach((tb) => {
        allocation += parseInt(tb.allocation);
      });

      return parseInt(allocation);
    },
  },
};
</script>
